exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-miejsce-z-klimatem-js": () => import("./../../../src/pages/miejsce-z-klimatem.js" /* webpackChunkName: "component---src-pages-miejsce-z-klimatem-js" */),
  "component---src-pages-okolica-js": () => import("./../../../src/pages/okolica.js" /* webpackChunkName: "component---src-pages-okolica-js" */),
  "component---src-pages-pokoje-js": () => import("./../../../src/pages/pokoje.js" /* webpackChunkName: "component---src-pages-pokoje-js" */)
}

